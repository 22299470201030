














































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import { AppModule } from '@/store/modules/app'
import { CustomField } from '@/utils/customFields'
import { createLeadplusFields } from '@/utils/leadplus'
import { savePreviewImage, updateFormById } from '@/api/leadplus/forms'
import html2canvas from 'html2canvas'

@Component({
    name: 'VsFormEditor',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsSectionHeader,
        VsLoader,
    },
})
export default class extends Vue {
    private open = false
    private loading = false
    private loading2 = false
    private loadingPreview = false
    form: any = null
    formId = ''
    initRoute = '/'
    listFields: any[] = []
    $refs: any

    private token = ''

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get extra () {
        return {
            autosave: {
                active: false,
                delay: 30,
            },
            hideWizard: false,
            showAutosaveBtn: false,
            enabledThemeSelection: false,
            form: JSON.parse(JSON.stringify(this.form)),
            listFields: this.listFields,
            showCloseButton: false,
            urlRulesEditable: true,
            initRoute: this.initRoute,
            lang: 'it',
            enableScreenshot: true,
            mediaLibrary: {
                url: 'app/user/medialibrary/browse/0/popup/?Fog=true',
                width: 800,
                height: 400,
            },
            disableFeatures: this.is4Dem ? [] : ['logo'],
            errorCallback: (e: any) => {
                console.log(e)
            },
        }
    }

    async openModal (form: any, customFields: CustomField[], initRoute?: string) {
        this.loading = true
        this.loadingPreview = false
        this.loading2 = true
        this.formId = form._id
        this.form = form
        this.initRoute = '/'
        if (initRoute) {
            this.initRoute = initRoute
        }
        this.listFields = createLeadplusFields(customFields)
        this.open = true
    }

    closeModal () {
        this.open = false
    }

    initRun () {
        const nodeStaticScript = document.createElement('script')
        nodeStaticScript.type = 'text/javascript'
        nodeStaticScript.setAttribute('src', `${process.env.VUE_APP_LEADPLUS_OLD_API || ''}/editor/node-static.js`)
        const appScript = document.createElement('script')
        appScript.type = 'text/javascript'
        appScript.setAttribute('src', `${process.env.VUE_APP_LEADPLUS_OLD_API || ''}/editor/app.js`)

        nodeStaticScript.onload = () => {
            this.$refs.fogIframe.contentWindow.document.body.appendChild(appScript)
        }
        appScript.onload = () => {
            this.$refs.fogIframe.contentWindow.runApp(this.token, '#app', this.formId, this.extra, () => { console.log('leadplusApp run') })
        }

        this.$refs.fogIframe.contentWindow.document.body.appendChild(nodeStaticScript)
    }

    initPreview () {
        const nodeStaticRenderScript = document.createElement('script')
        nodeStaticRenderScript.type = 'text/javascript'
        nodeStaticRenderScript.setAttribute('src', `${process.env.VUE_APP_LEADPLUS_OLD_API || ''}/editor/node-static-render.js`)
        const appRenderScript = document.createElement('script')
        appRenderScript.type = 'text/javascript'
        appRenderScript.setAttribute('src', `${process.env.VUE_APP_LEADPLUS_OLD_API || ''}/editor/app_render.js`)

        nodeStaticRenderScript.onload = () => {
            this.$refs.preview.contentWindow.document.body.appendChild(appRenderScript)
        }

        this.$refs.preview.contentWindow.document.body.appendChild(nodeStaticRenderScript)
        this.loading2 = false
    }

    async save () {
        const iframeWindow = this.$refs.fogIframe.contentWindow
        iframeWindow.saveForm(this.formId, async (data: any) => {
            this.loading = true
            data.form.flux.sign.emailAddresses = data.form.flux.sign.emailAddresses.map((el: any) => {
                return {
                    ...el,
                    address: el.address || el.adress || '',
                }
            })
            try {
                await updateFormById(this.formId, data.form)
                await this.openPreview(data.form)
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        })
    }

    async getScreenShot () {
        const iframeWindow = this.$refs.preview.contentWindow
        const el = iframeWindow.document.getElementsByTagName('BODY')[0]
        const canvas = await html2canvas(el, {
            windowWidth: 700,
            width: 700,
            height: 800,
            proxy: '/app/userapi/users/screenshot-images-proxy',
        })
        try {
            const base64 = canvas.toDataURL('image/jpeg', 0.1)
            // base64 = await this.processImage(base64)
            return base64
        } catch (e) {
            console.log(e)
        }
    }

    async processImage (base64: string, min_image_size = 20) {
        if (base64) {
            const old_size = this.calcImageSize(base64)
            if (old_size > min_image_size) {
                const resized = await this.reduceImageFileSize(base64)
                return resized
            } else {
                return base64
            }
        } else {
            return base64
        }
    }

    calcImageSize (base64: string) {
        let y = 1
        if (base64.endsWith('==')) {
            y = 2
        }
        const x_size = (base64.length * (3 / 4)) - y
        return Math.round(x_size / 1024)
    }

    async reduceImageFileSize (base64Str: string, MAX_WIDTH = 400, MAX_HEIGHT = 500) {
        const resized_base64: string = await new Promise((resolve) => {
            const img = new Image()
            img.src = base64Str
            img.onload = () => {
                const canvas = document.createElement('canvas')
                let width = img.width
                let height = img.height

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = width
                canvas.height = height
                const ctx = canvas.getContext('2d')
                if (!ctx) {
                    return base64Str
                }
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL('image/jpeg', 0.1)) // this will return base64 image results after resize
            }
        })
        return resized_base64
    }

    mounted () {
        window.addEventListener('message', this.checkMessage, false)
    }

    beforeDestroy () {
        window.removeEventListener('message', this.checkMessage)
    }

    async openPreview (form: any) {
        this.loadingPreview = true
        try {
            this.$refs.preview.contentWindow.doApp('', '#render', this.form._id, {
                form,
                noAnimations: true,
                listFields: [],
            }, undefined)
        } catch (e) {
            console.log(e)
            this.$emit('save')
            this.loading = false
            this.closeModal()
            this.loadingPreview = false
        }
    }

    async checkMessage (evt: any) {
        switch (evt.data.step) {
            case 'imready':
                if (this.loadingPreview) {
                    this.loadingPreview = false
                    try {
                        const screenShot = await this.getScreenShot()
                        if (screenShot) {
                            await savePreviewImage(this.formId, {
                                base64Image: screenShot,
                            })
                        }
                    } catch (e) {
                        console.log(e)
                    }
                    this.loading = false
                    this.$emit('save')
                    this.closeModal()
                } else {
                    this.loading = false
                }
        }
    }
}
