



















































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import { CustomField } from '@/utils/customFields'

@Component({
    name: 'VsFogFormPreviewModal',
    components: {
        VsFullTopBarLayout,
    },
})
export default class extends Vue {
    open = false
    form: any = null
    customFields: CustomField[] = []
    $refs: any
    private loading = true

    get isWhitelabel () {
        return !AppModule.consoleConf?.is4Dem
    }

    async openModal (form: any, customFields: CustomField[]) {
        this.form = form
        this.customFields = customFields
        this.open = true
    }

    closeModal () {
        this.open = false
        this.form = null
    }

    async loadForm () {
        this.loading = true
        const nodeStaticRenderScript = document.createElement('script')
        nodeStaticRenderScript.type = 'text/javascript'
        nodeStaticRenderScript.setAttribute('src', `${process.env.VUE_APP_LEADPLUS_OLD_API || ''}/editor/node-static-render.js`)
        const appRenderScript = document.createElement('script')
        appRenderScript.type = 'text/javascript'
        appRenderScript.setAttribute('src', `${process.env.VUE_APP_LEADPLUS_OLD_API || ''}/editor/app_render.js`)

        nodeStaticRenderScript.onload = () => {
            this.$refs.iframe.contentWindow.document.body.appendChild(appRenderScript)
        }

        appRenderScript.onload = () => {
            try {
                this.$refs.iframe.contentWindow.doApp('', '#render', this.form._id, {
                    form: this.form,
                    listFields: [],
                }, undefined)
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        }

        this.$refs.iframe.contentWindow.document.body.appendChild(nodeStaticRenderScript)
    }
}

